import React from "react"
import { CenterContent } from "../components/_index"
import { Grid } from "@material-ui/core"
import SiteMetadata from "../components/SiteMetaData"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Typography from "@material-ui/core/Typography"
import * as PropTypes from "prop-types"

const error = (props) => {
  const {
    data: {
      fileName: {
        childImageSharp: {
          fluid: { src: logoUrl },
        },
      },
    },
  } = props
  return (
    <Layout>
      <SiteMetadata />
      <CenterContent>
        <Grid
          container
          justify={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid item>
            <img src={logoUrl} alt={"logo"} />
          </Grid>
          <Grid item>
            <Typography
              variant={"h5"}
              style={{ whiteSpace: "break-spaces" }}
              align={"center"}
            >
              404 Page not found
              <p>
                Leider ist ein Fehler unterlaufen. Diese Seite scheint nicht zu
                existieren.
              </p>
            </Typography>
          </Grid>
        </Grid>
      </CenterContent>
    </Layout>
  )
}

error.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default error
